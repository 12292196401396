@import "./scss/_utility.scss";
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500;700&display=swap");

/* -- Variables -- */
$background: #0f0e12;
$default: #e5e5e5;
$subdued: rgba(#e5e5e5, 0.52);
$accent: #9585e0;
$accent-2: #84e0e0;

$breakpoint-lg: 1024px;
$breakpoint-md: 760px;
$breakpoint-sm: 480px;

@mixin swapHover($original-color) {
  transition: background-color 0.2s, color 0.2s;
  &:hover {
    background-color: $original-color;
    color: $background;
  }
}

/* -- Global Styles -- */
html,
body {
  font-family: "IBM Plex Mono", Menlo, Roboto Mono, monospace;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: $default;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.subdued {
  color: $subdued;
}

.accent {
  color: $accent;
}

.accent-2 {
  color: $accent-2;
}

a {
  color: $default;
  text-decoration: none;
  @include swapHover($default);

  &::before {
    content: "[";
  }
  &::after {
    content: "]";
  }
}

a.subdued {
  color: $subdued;
  @include swapHover($subdued);
}

a.accent {
  color: $accent;
  @include swapHover($accent);
}

a.accent-2 {
  color: $accent-2;
  @include swapHover($accent-2);
}

.container {
  width: 400px;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;

  @media (max-width: $breakpoint-lg) {
    width: 320px;
    padding: 16px;
  }

  @media (max-width: $breakpoint-sm) {
    width: calc(100vw - 48px);
    padding: 24px;
  }
}

.container-ascii {
  position: fixed;
  top: 0;
  left: 448px;
  height: 100vh;
  width: calc(100vw - 448px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  background: linear-gradient(rgba(229, 229, 229, 0.06), $background);

  @media (max-width: $breakpoint-lg) {
    left: 360px;
    width: calc(100vw - 360px);
  }

  @media (max-width: $breakpoint-md) {
    display: none;
  }
}

/* -- General -- */
.symbol {
  display: inline-block;
  width: 16px;
}

.img {
  height: 72px;
}

.top-section-profle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.top-section-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.section {
  display: flex;
  flex-direction: column;

  .section-header {
    display: flex;
    flex-direction: row;

    @media (max-width: $breakpoint-sm) {
      flex-direction: column;
    }
  }
}

